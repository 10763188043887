<template>
  <v-container fill-height fluid grid-list-xl pb-5 mb-5>
    <v-layout column white wrap ma-2>
      <!-- CONNECTED USER'S CUSTOMER'S PROJECTS -->
      <v-flex pt-2 pb-0 px-4 d-inline-flex>
        <div>
          <v-btn v-if="project.id" flat color="primary" @click="goToProject">
            <span class="text-truncate" style="max-width:12rem">
              {{ project.name }}
            </span>
          </v-btn>
          <v-icon v-if="project.id" small>
            mdi-arrow-right
          </v-icon>
          <v-btn v-if="sprint.id" flat color="primary" @click="goToSprint">
            <span class="text-truncate" style="max-width:12rem">
              {{ sprint.name }}
            </span>
          </v-btn>
          <v-icon v-if="sprint.id" small>
            mdi-arrow-right
          </v-icon>
          <v-btn
            v-if="userStory.id"
            flat
            color="primary"
            @click="goToUserStory"
          >
            <span class="text-truncate" style="max-width:12rem">
              {{ userStory.name }}
            </span>
          </v-btn>
          <v-icon v-if="userStory.id" small>
            mdi-arrow-right
          </v-icon>
          <v-btn flat color="primary">
            <span class="text-truncate" style="max-width:12rem">
              {{ userAcceptanceTest.name }}
            </span>
          </v-btn>
        </div>
      </v-flex>
      <v-divider />
      <v-flex px-4>
        <h2>
          {{ userAcceptanceTest.name }}
        </h2>
        <p
          v-if="userAcceptanceTest.comment"
          style="white-space: pre-line"
          v-html="userAcceptanceTest.comment"
        />
      </v-flex>
      <v-flex>
        <v-flex right py-0>
          <!-- v-if="isAdmin" -->
          <v-btn
            v-if="isAdmin || userAcceptanceTest.state === 'QUESTION'"
            center
            class="success"
            @click="addUATValidation"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
            <v-flex ml-2 pa-0>
              Recetter l'UAT
            </v-flex>
          </v-btn>
        </v-flex>
        <v-flex left py-0>
          <v-btn center class="quaternary" @click="goBack">
            <v-icon>mdi-arrow-left</v-icon>
            <v-flex ml-2 pa-0>
              Retour à l'US
            </v-flex>
          </v-btn>
        </v-flex>
      </v-flex>
      <material-DataTableUatValidation
        :items="uatValidations"
        :loading="loading"
        can-search
        @click:row="uatValidationDetails"
        @click:edit="editUATValidation"
        @click:delete="deleteUATValidation"
      />
      <!-- <v-flex>
        <v-card>
          <v-card-title>
            <h5>
            <v-icon color="info">
              mdi-help-circle-outline
            </v-icon>
            Aides
          </h5>
          </v-card-title>
          <v-flex>
            <p></p>
          </v-flex>
        </v-card>
      </v-flex> -->

      <ui-DialogBox
        title="Suppression d'une UAT validation"
        msg="Voulez-vous vraiment supprimer l'UAT validation ?"
        :show="uatValidationToDelete > 0"
        icon-title="mdi-delete-outline"
        cancel-icon="close-circle-outline"
        ok-icon="check-circle-outline"
        @cancel="uatValidationToDelete = null"
        @ok="confirmDeleteUATValidation(uatValidationToDelete)"
      />

      <!-- SNACKBAR -->
      <ui-SnackBarV2
        :text="text"
        :color="color"
        :bottom="bottom"
        :top="top"
        :right="right"
        :left="left"
        :snackbar="snackbar"
      />
    </v-layout>
  </v-container>
</template>
<script>
import RoleMixin from '@/mixins/RoleMixin';
import SnackBarMixin from '@/mixins/SnackBarMixin';

export default {
  name: 'UserAcceptanceTestDetails',

  mixins: [RoleMixin, SnackBarMixin],

  data: () => ({
    userAcceptanceTestId: null,
    loading: false,
    uatValidationToDelete: null,
  }),

  computed: {
    project() {
      return this.$store.getters['project/getProject']() || null;
    },

    sprint() {
      return this.$store.getters['sprint/getSprint']() || null;
    },

    userStory() {
      return this.$store.getters['userStory/getUserStory']() || null;
    },

    userAcceptanceTest() {
      return (
        this.$store.getters['userAcceptanceTest/getUserAcceptanceTest']() ||
        null
      );
    },

    uatValidations() {
      return (
        this.$store.getters[
          'uatValidation/getUserAcceptanceTestUatValidations'
        ]() || []
      );
    },
  },

  created() {
    this.userAcceptanceTestId = this.$route.params.id;
  },

  mounted() {
    // get the sprint and the user stories associate
    this.$store.commit('app/showLoader');
    Promise.all([
      this.$store.dispatch(
        'userAcceptanceTest/get_user_acceptance_tests_by_id',
        this.userAcceptanceTestId
      ),
      this.$store.dispatch(
        'uatValidation/get_user_acceptance_test_uat_validations',
        this.userAcceptanceTestId
      ),
    ])
      .then((response) => {
        console.log('UserAcceptanceTest OK', response[0]);
        console.log('UserAcceptanceTest uatValidations OK', response[1]);

        // get the User Story associate to the user acceptance test
        if (
          this.userStory &&
          parseInt(this.userStory.id) !==
            parseInt(this.userAcceptanceTest.user_stories_id)
        ) {
          this.$store.dispatch(
            'userStory/get_user_stories_by_id',
            this.userAcceptanceTest.user_stories_id
          );
        }

        // get the sprint associate to the User Story
        if (
          this.sprint &&
          parseInt(this.project.id) !==
            parseInt(this.userAcceptanceTest.sprints_id)
        ) {
          this.$store
            .dispatch(
              'sprint/get_sprint_by_id',
              this.userAcceptanceTest.sprints_id
            )
            .then((response) => {
              console.log('Sprint OK', response);
            });
        }

        // get the project associate to the sprint
        if (
          this.project &&
          parseInt(this.project.id) !==
            parseInt(this.userAcceptanceTest.projects_id)
        ) {
          this.$store
            .dispatch(
              'project/get_project_by_id',
              this.userAcceptanceTest.projects_id
            )
            .then((response) => {
              console.log('Project OK', response);
            });
        }
      })
      .catch((error) => {
        console.log('Erreur', error);
        this.snack({
          position: 'bottom',
          align: 'center',
          color: 'error',
          text:
            'Impossible de récupérer les user stories du sprint : ' +
            error.message,
        });
      })
      .finally(() => {
        this.$store.commit('app/showLoader');
        this.$store.commit('app/resetDashboardIds');
        this.$store.commit('app/setDashboardIds', {
          item: 'project',
          id: this.userAcceptanceTest.projects_id,
        });
        this.$store.commit('app/setDashboardIds', {
          item: 'sprint',
          id: this.userAcceptanceTest.sprints_id,
        });
        this.$store.commit('app/setDashboardIds', {
          item: 'userStory',
          id: this.userAcceptanceTest.user_stories_id,
        });
        this.$store.commit('app/setDashboardIds', {
          item: 'userAcceptanceTest',
          id: this.userAcceptanceTest.id,
        });
      });
  },

  methods: {
    goBack() {
      console.log('goBack');
      this.$router.push({
        path: `/userStory/${this.userAcceptanceTest.user_stories_id}`,
      });
    },

    goToProject() {
      console.log('goToProject');
      this.$router.push({
        path: `/project/${this.userAcceptanceTest.projects_id}`,
      });
    },

    goToSprint() {
      console.log('goToSprint');
      this.$router.push({
        path: `/sprint/${this.userAcceptanceTest.sprints_id}`,
      });
    },

    goToUserStory() {
      console.log('goToUserStory');
      this.$router.push({
        path: `/userStory/${this.userAcceptanceTest.user_stories_id}`,
      });
    },

    uatValidationDetails(id) {
      console.log('uatValidationDetails', id);
      // this.$router.push({ path: `/uatValidation/${id}` });
    },

    addUATValidation() {
      console.log('addUATValidation');
      this.$router.push({ path: `/uatValidation/create` });
    },

    editUATValidation(id) {
      console.log('editUATValidation', id);
      this.$router.push({ path: `/uatValidation/${id}/edit` });
    },

    deleteUATValidation(id) {
      console.log('deleteUATValidation', id);
      this.uatValidationToDelete = id;
    },
    confirmDeleteUATValidation(id) {
      console.log('confirmDeleteUATValidation', id);
      this.$store
        .dispatch('uatValidation/delete_uat_validations_by_id', id)
        .then(() => {
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'success',
            text: 'UAT validation supprimé avec succès',
          });
        })
        .catch((error) => {
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'error',
            text: "Impossible de supprimer l'UAT validation : " + error.message,
          });
        })
        .finally(() => {
          this.uatValidationToDelete = null;
        });
    },
  },
};
</script>

<style></style>
